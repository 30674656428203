import React from 'react';
import SEO from '../components/seo/SEO';
import styles from './Browser-cookies.module.scss';

import Layout from '../components/layout/Layout';

const ContactPage = () => {
  return (
    <>
      <SEO title={'Browser cookies. Read about the data we collect and handle.'} />
      <Layout>
        <div className="grid_text_image grid_vertical_padding sectionVeryLong pb-96 mb-96">
          <div>
            <h1 className={styles.welcomeText}>Browser cookies </h1>
            <p className={styles.descriptionText}>
              Most webpages today use browser cookies. With effect from and including 1st of July
              2013, all Norwegian webpages using such cookies were obliged to collect consent from
              their users, allowing the sites to store cookies on the user’s device, in accordance
              with the Electronic Communications Act, §2-7b.
            </p>
            <p className={styles.descriptionText}>
              By using our webpage, you give your consent to us storing cookies in your browser.
            </p>
            <p className={styles.descriptionText}>
              A browser cookie is a small text file, stored locally on the user’s unit when a
              webpage is accessed. The cookie may have a range of functions. The information stored
              may contribute to customising the webpage and improving the user’s experience of the
              page. When visiting a webpage, the cookie asks for permission to be stored on your
              device. Most modern browsers accept cookies automatically, but the user may at any
              time withdraw the consent. This is done through administering the settings for browser
              cookies in the relevant browser. Many webpages, including this one, will not function
              properly without the user’s consent.
            </p>

            <p className={styles.descriptionText}>
              <b>Third parties</b> <br />
              We engage{' '}
              <a
                href={`https://privacy.google.com/businesses/processorterms/`}
                title="Google Ads Data Processing"
              >
                Google Analytics
              </a>{' '}
              to monitor the activity on our different sites and then optimise our webpages using
              this information. Google places browser cookies to collect information on our webpage
              traffic. The cookies sample anonymous information about the sites visited by the user,
              the webpage the user arrived from, the webpage the user left our site for, the
              duration of the visit, a rough estimate of the user’s position and which types of
              operating system and device are used.
            </p>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ContactPage;
